import React, { Component } from 'react';
import { Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import './RunnersUp.css';

export default class RunnersUp extends Component {
    constructor(props) {
        super(props);

        this.generateRunnersUp = this.generateRunnersUp.bind(this);
    }
    
    generateRunnersUp() {
        var entries = [];
        for(var e in this.props.runnersup) {

            entries.push(
                <Row className="RunnerUp TextCenter">
                    <h4>
                        <span className="RunnerUpLabel">Runner Up</span>
                        {this.props.runnersup[e].web ? <a href={this.props.runnersup[e].web}>{this.props.runnersup[e].name} <FontAwesomeIcon icon={faArrowCircleRight} /></a> : this.props.runnersup[e].name}
                    </h4>
                </Row>
            );
        }

        return entries;
    }

    render() {
        return this.generateRunnersUp();
    }
}
