import 'whatwg-fetch';
import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import Media from 'react-media';
import imageURLs from './imageURLs.json';
import Placeholder from './placeholder.jpg';

export default class WinnerImage extends Component {
    constructor(props) {
        super(props);

        this.checkStatus = this.checkStatus.bind(this);
    }

    checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }  
    }

    getITMSImage(url) {
        const idMatch = url.match(/id[0-9]+/);
        if(idMatch.length > 0) {
            const id = idMatch[0].substring(2);
            if(imageURLs[id]) {
                return imageURLs[id];
            }
        }
        return null;
    }

    render() {
        var imageURL;
        if(this.props.winner.appicon) {
            imageURL = this.props.winner.appicon;
            return (
                <Image className="AppIcon mx-auto" src={imageURL} />
            )
        }
        if(this.props.winner.image) {
            imageURL = this.props.winner.image;
            if (this.props.winner.image2 && Math.random() > 0.5) {
                imageURL = this.props.winner.image2;
            }
            if (this.props.winner.imageDarkMode) {
                return (
                    <Media query="(prefers-color-scheme: dark)">
                        {matches =>
                            matches ? (
                                <Image className="WinnerImage mx-auto TextCenter" src={this.props.winner.imageDarkMode} />
                            ) : (
                                <Image className="WinnerImage mx-auto" src={this.props.winner.image} />
                            )
                        }
                    </Media>
                )
            }
            return (
                <Image className="WinnerImage mx-auto" src={imageURL} />
            )
        }
        if(this.props.winner.appstore) {
            imageURL = this.getITMSImage(this.props.winner.appstore);
            return (
                <Image className="AppIcon mx-auto" src={imageURL} />
            )
        }
        if (this.props.winner.macappstore && this.props.winner.isAppicon) {
            imageURL = this.getITMSImage(this.props.winner.macappstore);
            return (
                <Image className="AppIcon mx-auto" src={imageURL} />
            )
        }
        if(this.props.winner.macappstore) {
            imageURL = this.getITMSImage(this.props.winner.macappstore);
        }
        if(this.props.winner.itunes) {
            imageURL = this.getITMSImage(this.props.winner.itunes);
        }
        if (this.props.winner.appleTVWatch) {
            imageURL = this.getITMSImage(this.props.winner.appleTVWatch);
        }
        if (this.props.winner.appleTVGet) {
            imageURL = this.getITMSImage(this.props.winner.appleTVGet);
        }
        if(this.props.winner.appleBooks) {
            imageURL = this.getITMSImage(this.props.winner.appleBooks);
        }
        if(this.props.winner.podcastApple) {
            imageURL = this.getITMSImage(this.props.winner.podcastApple);
        }

        if(imageURL == null) {
            if(this.props.showart) {
                imageURL = this.props.showart;
            } else {
                imageURL = Placeholder
            }
        }
        

        return (
            <Image className="WinnerImage mx-auto" src={imageURL} />
        )
        
    }
}
