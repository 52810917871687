import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSafari, faImdb, faAmazon } from '@fortawesome/free-brands-svg-icons';
import { faPodcast, faRssSquare } from '@fortawesome/free-solid-svg-icons'
import './WinnerInfo.css'

export default class WinnerInfo extends Component {
    constructor(props) {
        super(props);

        this.generateInfo = this.generateInfo.bind(this);
    }
    
    generateInfo() {
        let info = [
            (<h6 className="WinnerAbove TextCenter"> Winner</h6>),
            (<h2 className="WinnerName TextCenter">{this.props.winner.name}</h2>)
        ];
        if(this.props.winner.author !== undefined) {
            info.push(
                <h4 className="WinnerAuthor TextCenter">by {this.props.winner.author}</h4>
            )
        }
        if(this.props.winner.note !== undefined) {
            info.push(
                <h5 className="WinnerNote TextCenter">{this.props.winner.note}</h5>
            )
        }
        if(this.props.winner.appstore !== undefined) {
            info.push(
                <a href={this.props.winner.appstore}>
                    <Image className="AppStoreBadge TextCenter" src="/img/appstore.png" />
                </a>
            )
        }
        if(this.props.winner.macappstore !== undefined) {
            info.push(
                <a href={this.props.winner.macappstore}>
                    <Image className="AppStoreBadge TextCenter" src="/img/macappstore.png" />
                </a>
            )
        }
        if (this.props.winner.appleTVWatch !== undefined) {
            info.push(
                <a href={this.props.winner.appleTVWatch}>
                    <Image className="AppStoreBadge TextCenter" src="/img/appletv-watch.png" />
                </a>
            )
        }
        if (this.props.winner.appleTVGet !== undefined) {
            info.push(
                <a href={this.props.winner.appleTVGet}>
                    <Image className="AppStoreBadge TextCenter" src="/img/appletv-get.png" />
                </a>
            )
        }
        if(this.props.winner.itunes !== undefined) {
            info.push(
                <a href={this.props.winner.itunes}>
                    <Image className="AppStoreBadge TextCenter" src="/img/itunes.png" />
                </a>
            )
        }
        if(this.props.winner.appleBooks !== undefined) {
            info.push(
                <a href={this.props.winner.appleBooks}>
                    <Image className="AppStoreBadge TextCenter" src="/img/applebooks.png" />
                </a>
            )
        }
        if(this.props.winner.podcastApple !== undefined) {
            info.push(
                <a href={this.props.winner.podcastApple}>
                    <Image className="AppStoreBadge TextCenter" src="/img/applepodcasts.png" />
                </a>
            )
        }
        if(this.props.winner.imdb !== undefined) {
            info.push(
                <h4>
                    <a href={this.props.winner.imdb}>
                        <FontAwesomeIcon icon={faImdb} /> View on IMDB
                    </a>
                </h4>
            )
        }
        if(this.props.winner.podcastRss !== undefined) {
            info.push(
                <h4>
                    <a href={this.props.winner.podcastRss}>
                        <FontAwesomeIcon icon={faRssSquare} /> Subscribe via RSS
                    </a>
                </h4>
            )
        }
        if(this.props.winner.episode !== undefined) {
            info.push(
                <h4>
                    <a href={this.props.winner.episode}>
                        <FontAwesomeIcon icon={faPodcast} /> Listen on The Incomparable
                    </a>
                </h4>
            )
        }
        if(this.props.winner.amazon !== undefined) {
            info.push(
                <h4>
                    <a href={this.props.winner.amazon}>
                        <FontAwesomeIcon icon={faAmazon} /> View on Amazon
                    </a>
                </h4>
            )
        }
        if(this.props.winner.web !== undefined) {
            info.push(
                <h4>
                    <a href={this.props.winner.web}>
                        <FontAwesomeIcon icon={faSafari} /> Visit Website
                    </a>
                </h4>
            )
        }

        return info;
    }

    render() {
        return this.generateInfo();
    }
}
