import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import Award from './Award';
import Categories from './categories.json'

const years = [
    require("./2023.json"),
    require("./2022.json"),
    require("./2021.json"),
    require("./2020.json"),
    require("./2019.json"),
    require("./2018.json"),
    require("./2017.json"),
    require("./2016.json"),
    require("./2015.json"),
    require("./2014.json")
]

export default class AwardsCategory extends Component {
    constructor(props) {
        super(props);

        let categoryObj;
        for(let c in Categories.categories) {
            if(Categories.categories[c].id === props.category) {
                categoryObj = Categories.categories[c];
            }
        }

        if (categoryObj == null) {
            this.state = {
                category: props.category
            }
        } else {
            const awards = this.findAwards(props.category, categoryObj.subcategories);
            this.state = {
                category: props.category,
                categoryName: categoryObj.name,
                awards: awards
            }
        }
    }

    findAwards(category, subcategories) {
        let awards = [];
        for(let year in years) {
            for(let found of years[year]?.categories) {
                if (found.id === category) {
                    let entry = found;
                    let showart = years[year].showart;
                    awards.push(
                        <Award key={entry} entry={entry} name={years[year].year} showart={showart} image={years[year].image} darkModeImage={years[year].darkModeImage} />
                    )
                }
                else if (subcategories) {
                    for (let s of subcategories) {
                        if (found.id === s.id) {
                            let entry = found;
                            let showart = years[year].showart;
                            awards.push(
                                <Award key={entry} entry={entry} name={years[year].year} subname={s.name} showart={showart} image={years[year].image} darkModeImage={years[year].darkModeImage} />
                            )
                        }
                    }
                }
            }
        }

        return awards;
    }

    render() {
        let returning;

        if (this.state.awards != null) {
            let jumbo = [(<Jumbotron className="YearHeader">
                <h1>{this.state.categoryName} Winners</h1>
            </Jumbotron>)];
            returning = jumbo.concat(this.state.awards);
        } else {
            let jumbo = [(<Jumbotron className="YearHeader">
                <h2>No Winners found for {this.state.category}</h2>
            </Jumbotron>)];
            returning = jumbo;
        }

        return returning;
    }
}
