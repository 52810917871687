import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Media from 'react-media';
import WinnerInfo from './WinnerInfo';
import RunnersUp from './RunnersUp';

import './Award.css';
import WinnerImage from './WinnerImage';

export default class Award extends Component {

    render() {
        let categoryName;
        let categoryHeadingTextDiv = this.props.subname != null ? 'CategoryName CategoryNameAndSubname' : 'CategoryName CategoryNameOnly';
        if(this.props.image != null) {
            categoryName = (
                <Row className="CategoryHeader justify-content-center">
                    <Col sm={3} className="TextCenter">
                        <Media query="(prefers-color-scheme: dark)">
                            {matches =>
                                matches ? (
                                    <Image className="YearBadge mx-auto" src={this.props.darkModeImage} />
                                ) : (
                                        <Image className="YearBadge mx-auto" src={this.props.image} />
                                    )
                            }
                        </Media>
                    </Col>
                    <Col sm={9} className="TextCenter">
                        <h1 id={this.props.name} className={categoryHeadingTextDiv}>{this.props.name}
                            {/*<a className="AnchorHeading" href={"#" + this.props.name}><FontAwesomeIcon icon={faLink} /></a>*/}
                        </h1>
                        {this.props.subname ? <h4 className="CategorySubname">{this.props.subname}</h4> : ''}
                    </Col>
                </Row>
            )
        } else {
            categoryName = (
                <Row className="TextCenter my-auto">
                    <h1 id={this.props.entry.id} className={categoryHeadingTextDiv}>{this.props.name} {/*<a className="AnchorHeading" href={"#" + this.props.entry.id}><FontAwesomeIcon icon={faLink} /></a>*/}</h1>
                    {this.props.subname ? <h4 className="CategorySubname">{this.props.subname}</h4> : ''}
                </Row>
            )
        }

        return (
            <Container className="Award">
                {categoryName}
                <Row>
                    <Col md={4} className="TextCenter">
                        <WinnerImage className="WinnerImage TextCenter" showart={this.props.showart} winner={this.props.entry.winner} />
                    </Col>
                    <Col md={8} className="WinnerInfo TextCenter">
                        <WinnerInfo className="TextCenter" winner={this.props.entry.winner} />
                    </Col>
                </Row>
                <RunnersUp className="RunnersUp" runnersup={this.props.entry.runnersup} />
            </Container>
        );
    }
}
