import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AwardsCategory from './AwardsCategory'
import Categories from './categories.json'

export default class SortByCategory extends Component {
    constructor(props) {
        super(props);

        const catVal = props.location.pathname.replace(props.match.path + '/', '');
        let catLabel = 'Select a category';
        for (let i of Categories.categories) {
            console.log(i);
            if (i.id === catVal) {
                catLabel = i.name;
                break;
            }
        }
        this.state = {
            category: catLabel
        };

        this.selectCategory = this.selectCategory.bind(this);
        this.generateCategoryLinks = this.generateCategoryLinks.bind(this);
    }

    selectCategory(selected) {
        const catVal = selected.replace(this.props.match.path + '/', '');
        let catLabel = 'Select a category';
        for (let i of Categories.categories) {
            console.log(i);
            if (i.id === catVal) {
                catLabel = i.name;
                break;
            }
        }
        this.setState({
            category: catLabel
        })
    }

    generateCategoryLinks() {
        var list = [];
        for(let c of Categories.categories) {
            list.push(
                <LinkContainer to={`${this.props.match.path}/${c.id}`}>
                    <NavDropdown.Item>{c.name}</NavDropdown.Item>
                </LinkContainer>
            )
        }
        return list;
    }

    render() {
        return (
            <Router>
                <div class="ViewByCategory">
                    <Navbar collapseOnSelect className="Navbar-Links py-0" bg="dark" variant="dark" expand="sm">
                        <Container>
                            <Nav activeKey={this.state.category}
                                onSelect={this.selectCategory}
                                className="mx-auto text-center Nav-Links">
                                <NavDropdown className="mx-auto" title={this.state.category} id="basic-nav-dropdown">
                                    {this.generateCategoryLinks()}
                                </NavDropdown>
                            </Nav>
                        </Container>
                    </Navbar>

                    <Route path={`${this.props.match.path}/:id`} render={props => (
                        <AwardsCategory {...props} key={props.match.params.id} category={props.match.params.id}></AwardsCategory>
                    )} />
                </div>
            </Router>
        );
    }
}
