import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Form, Navbar, NavDropdown, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import AwardsYear from './AwardsYear';

class SortByYear extends Component {
    years = [
        {
            name: '2023',
            path: '2023'
        },
        {
            name: '2022',
            path: '2022'
        },
        {
            name: '2021',
            path: '2021'
        },
        {
            name: '2020',
            path: '2020'
        },
        {
            name: '2019',
            path: '2019'
        },
        {
            name: '2018',
            path: '2018'
        },
        {
            name: '2017',
            path: '2017'
        },
        {
            name: '2016',
            path: '2016'
        },
        {
            name: '2015',
            path: '2015'
        },
        {
            name: '2014',
            path: '2014'
        },
        {
            name: 'Lifetime Achievement',
            path: 'lifetime-achievement'
        },
        {
            name: 'Hall of Fame',
            path: 'hall-of-fame'
        }
    ];

    constructor(props) {
        super(props);

        const yearVal = props.location.pathname.replace(props.match.path + '/', '');
        let yearLabel = 'Select a year';
        for (let i of this.years) {
            if (i.path == yearVal) {
                yearLabel = i.name;
                break;
            }
        }
        this.state = {
            year: yearLabel
        };

        this.selectYear = this.selectYear.bind(this);
    }

    selectYear(selected) {
        this.setState({
            year: selected
        })
    }

    render() {
        return (
            <Router>
                <div className="ViewByYear">
                    <Navbar collapseOnSelect className="Navbar-Links py-0" bg="dark" variant="dark">
                        <Container>
                            <Nav activeKey={this.state.year}
                                onSelect={this.selectYear}
                                className="mx-auto text-center Nav-Links">
                                <NavDropdown className="mx-auto" title={this.state.year} id="basic-nav-dropdown">
                                    {this.years.map((element, index) => {
                                        return <LinkContainer to={`${this.props.match.path}/${element.path}`}>
                                            <NavDropdown.Item eventKey={element.name}>{element.name}</NavDropdown.Item>
                                        </LinkContainer>
                                    })}
                                </NavDropdown>
                            </Nav>
                        </Container>
                    </Navbar>

                    <Route path={`${this.props.match.path}/:id`} render={ props => (
                        <AwardsYear {...props} key={props.match.params.id} year={props.match.params.id}></AwardsYear>
                    )}/>
                </div>
            </Router>

        );
    }
}

export default SortByYear;
